import { Fields } from '@hako/core'
import { useHatch } from '@/modules/hatch/hooks/useHatch'

export const BuyButton = () => {
    const { offers } = useHatch()
    // useSmoothScroll();

    if (offers === undefined || offers.length === 0) {
        return null
    }

    const atLeastOneIsAvailableToBuy =
        offers.find((offer) => offer.isAvailable) !== undefined
    return (
        <a href="#buy" className="u-full">
            <button className="btn btn--small btn--primary btn--light btn--no-wrap js-hatch-buy">
                {atLeastOneIsAvailableToBuy ? (
                    <Fields.Text
                        editable={false}
                        key={atLeastOneIsAvailableToBuy}
                        as="span"
                        scope="global"
                        slug="buy-now-button-active"
                    >
                        Buy now
                    </Fields.Text>
                ) : (
                    <Fields.Text
                        editable={false}
                        key={atLeastOneIsAvailableToBuy}
                        as="span"
                        scope="global"
                        slug="buy-now-button-options"
                    >
                        Buying options
                    </Fields.Text>
                )}
            </button>
        </a>
    )
}
