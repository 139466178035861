import React, { useState } from 'react'

export const LanguagePopupContext = React.createContext()
export const LanguagePopupProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <LanguagePopupContext.Provider value={{ isOpen, setIsOpen }}>
            {children}
        </LanguagePopupContext.Provider>
    )
}
