import { SupportLink } from '@/components/SupportLink'
import { BuyButton } from '@/components/BuyButton'
import { useProduct } from '@/pages/products/hooks/useProduct'

export const MobileProductBar = () => {
    const product = useProduct()

    if (!product) {
        return null
    }

    return (
        <div className="mobile-product-bar grid u-padding-top-small u-padding-bottom-small">
            <div className="grid-container u-flex-row u-align-items-center u-justify-content-space-between column-gap-small">
                <div
                    style={{
                        overflowWrap: 'anywhere',
                        minWidth: '30%',
                        maxWidth: '50%',
                    }}
                >
                    {product.name}
                </div>

                <div className="u-flex-row u-flex-flow u-align-items-center u-justify-content-flex-end row-gap-small column-gap-xsmall">
                    <SupportLink />
                    <BuyButton />
                </div>
            </div>
        </div>
    )
}
