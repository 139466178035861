import React from 'react'

export const ProductContext = React.createContext({})

export const ProductProvider = ({ children, product }) => {
    return (
        <ProductContext.Provider value={product}>
            {children}
        </ProductContext.Provider>
    )
}
