export class Offer {
    constructor({ purchaseLink, price, logo, isAvailable }) {
        this.purchaseLink = purchaseLink
        this.price = price
        this.logo = logo
        this.isAvailable = isAvailable
    }

    static createFromSingleRow(row) {
        const purchaseLink = row.purchase_link
        const price = row.price_local
        const logo = row.logo
        const isAvailable =
            row?.stock_info && row?.stock_qty !== undefined && row.stock_qty > 0

        return new Offer({
            purchaseLink,
            price,
            logo,
            isAvailable,
        })
    }
}
