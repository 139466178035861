import { Offer } from './data-objects/Offer'

export const fetchOffers = async (affiliateId, productId, region) => {
    try {
        const response = await fetch(
            `https://gethatch.com/wtbonline/affiliate/${affiliateId}/json?mpn=${productId}&region=${region}`
        )
        const data = await response.json()
        const offers = data?.iceleads_interface?.retailers?.retailer || []
        return offers.map(Offer.createFromSingleRow)
    } catch (e) {
        console.error(e)
        throw new Error('Error during fetching hatch offers')
    }
}
