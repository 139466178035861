import { Fields, useMenu } from '@hako/core'
import Link from 'next/link'
import * as Icons from '@/components/Icons'
import { Socials } from '@/components/Socials'

export const Footer = () => {
    const menu = useMenu('footer')
    return (
        <>
            <footer className="footer grid">
                <div className="grid-container u-flex-row u-flex-mobile-column u-justify-content-space-between u-padding-top-large">
                    <div className="logo">
                        <a href="/" aria-label="Link to homepage">
                            <Icons.Evnia />
                        </a>
                    </div>
                    <div className="footer-menu u-flex-row u-flex-mobile-column">
                        {(menu?.items || []).map((item, index) => (
                            <div key={index} className="u-flex-column">
                                {item.items.map((link, index) => {
                                    return (
                                        <Link
                                            key={index}
                                            href={link.value}
                                            target={link.target}
                                        >
                                            {link.name}
                                        </Link>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                    <div className="footer-right">
                        <div className="socials">
                            <Socials />
                        </div>
                        <div className="logo-philips">
                            <Icons.Philips />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="copyright">
                        © {new Date().getFullYear()}{' '}
                        <Fields.Text
                            as="span"
                            slug="footer-copyright"
                            scope="global"
                        >
                            Copyright
                        </Fields.Text>
                    </div>
                </div>
            </footer>
        </>
    )
}
