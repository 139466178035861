import * as Icons from '@/components/Icons'
import { useSettings } from '@hako/core'

export const Socials = () => {
    const facebook = useSettings('facebook')
    const twitter = useSettings('twitter')
    const linkedin = useSettings('linkedin')
    const instagram = useSettings('instagram')

    return (
        <>
            {facebook?.value && (
                <a
                    href={facebook.value}
                    target="_blank"
                    rel="noreferrer"
                    title={facebook.name}
                >
                    <Icons.Facebook />
                </a>
            )}

            {instagram?.value && (
                <a
                    href={instagram.value}
                    target="_blank"
                    rel="noreferrer"
                    title={instagram.name}
                >
                    <Icons.Instagram />
                </a>
            )}

            {twitter?.value && (
                <a
                    href={twitter.value}
                    target="_blank"
                    rel="noreferrer"
                    title={twitter.name}
                >
                    <Icons.Twitter />
                </a>
            )}

            {linkedin?.value && (
                <a
                    href={linkedin.value}
                    target="_blank"
                    rel="noreferrer"
                    title={linkedin.name}
                >
                    <Icons.Linkedin />
                </a>
            )}
        </>
    )
}
