import { Navigation } from '@/components/Navigation'
import { Footer } from '@/components/Footer'
import dynamic from 'next/dynamic'

const CookieBar = dynamic(() => import('@/components/CookieBar'), {
    loading: () => null,
    ssr: false,
})

const AvailableLanguageNotice = dynamic(
    () => import('@/components/languages/AvailableLanguageNotice'),
    {
        loading: () => null,
        ssr: false,
    }
)

export const Layout = ({ children, theme = undefined }) => {
    const themeClass = theme ? `theme-${theme}` : ''
    return (
        <div id="app" className={themeClass}>
            {/*{!isProd() && (*/}
            {/*    <div className="grid local-grid local-overlay">*/}
            {/*        <div className="grid-container grid">*/}
            {/*            <div id="col-1">1</div>*/}
            {/*            <div id="col-2">2</div>*/}
            {/*            <div id="col-3">3</div>*/}
            {/*            <div id="col-4">4</div>*/}
            {/*            <div id="col-5">5</div>*/}
            {/*            <div id="col-6">6</div>*/}
            {/*            <div id="col-7">7</div>*/}
            {/*            <div id="col-8">8</div>*/}
            {/*            <div id="col-9">9</div>*/}
            {/*            <div id="col-10">10</div>*/}
            {/*            <div id="col-11">11</div>*/}
            {/*            <div id="col-12">12</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            <AvailableLanguageNotice />
            <Navigation />
            <div>{children}</div>
            <Footer />
            <CookieBar />
        </div>
    )
}
