import { useCallback, useEffect } from 'react'

export const useKeyPress = (targetKey, callback = () => {}) => {
    const downHandler = useCallback(({ key }) => {
        if (key === targetKey) {
            callback?.()
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        return () => {
            window.removeEventListener('keydown', downHandler)
        }
    }, [downHandler])
}
