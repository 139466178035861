import { useMobileMenu } from '@/components/mobile-menu/hooks/useMobileMenu'

export const Header = ({ children }) => {
    const { isOpen } = useMobileMenu()
    return (
        <header className={`header grid ${isOpen ? 'header--active' : ''}`}>
            {children}
        </header>
    )
}
