import { Fields, useTranslation } from '@hako/core'
import { useProduct } from '@/pages/products/hooks/useProduct'

export const SupportLink = () => {
    const product = useProduct()
    if (!product || product?.support_link === undefined) {
        return null
    }

    return (
        <a
            target="_blank"
            href={product?.support_link}
            rel="nofollow noreferrer"
            className="u-full"
        >
            <button className="btn btn--small btn--outline btn--no-wrap u-margin-left-small">
                <Fields.Text
                    as="span"
                    scope="global"
                    slug="navigation-support"
                    editable={false}
                >
                    Support
                </Fields.Text>
            </button>
        </a>
    )
}
