import React, { useEffect } from 'react'
import { Fields, useLocales } from '@hako/core'
import { useKeyPress } from '@/hooks/useKeyPress'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import * as Icons from '@/components/Icons'
import { useLanguagesPopup } from '@/components/languages/hooks/useLanguages'

export const SingleLanguage = ({ item, onClick = () => {}, current }) => {
    let classes = classNames({
        'u-font-bold': current?.code === item.code,
        'u-text-opacity-25': !item?.active,
    })

    const clickHandle = (event) => {
        event.preventDefault()
        onClick(item)
    }

    return (
        <li key={item.code}>
            <a
                onClick={clickHandle}
                className={classes}
                title={
                    !item?.active
                        ? 'This language is disabled and visible only internally.'
                        : item.name
                }
            >
                {item.name}
            </a>
        </li>
    )
}

export const LanguagePopup = () => {
    const { all, preferred, current } = useLocales({
        groupedByRegion: true,
    })
    const { isOpen, setIsOpen } = useLanguagesPopup()
    const router = useRouter()

    useEffect(() => {
        if (!isOpen) {
            document.body.style.overflow = 'visible'
        } else {
            document.body.style.overflow = 'hidden'
        }
    }, [isOpen])

    useKeyPress('Escape', () => {
        setIsOpen(false)
    })

    const showPreferred = preferred && current?.code !== preferred?.code

    const handleSelect = (selected) => {
        setIsOpen(false)
        router.push('/', undefined, { locale: selected.code })
    }

    if (!isOpen) {
        return null
    }

    return (
        <div className="languages-wrapper">
            <div className="languages">
                <div className="row languages-row">
                    {/* This section is twice for set margin top for body because we have header as a fixed element */}
                    <div
                        className="languages__header languages__header--hidden"
                        aria-hidden="true"
                    >
                        <div className="logo">
                            <Icons.Evnia />
                        </div>

                        <Fields.Text
                            as="h2"
                            slug="language-popup-header"
                            scope="global"
                            className="h2 u-padding-top-medium u-padding-bottom-xsmall"
                        >
                            Select your location and language
                        </Fields.Text>

                        {showPreferred && (
                            <div>
                                <Fields.Text
                                    as="span"
                                    slug="language-popup-suggestion-text"
                                    scope="global"
                                >
                                    Suggested location and language
                                </Fields.Text>{' '}
                                <button
                                    className="u-txt-medium"
                                    onClick={() => handleSelect(preferred)}
                                >
                                    {preferred.name}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="languages__header">
                        <div className="logo">
                            <Icons.Evnia />
                        </div>

                        <Fields.Text
                            as="h2"
                            slug="language-popup-header"
                            scope="global"
                            className="h2 u-padding-top-medium u-padding-bottom-xsmall"
                        >
                            Select your location and language
                        </Fields.Text>

                        {showPreferred && (
                            <div>
                                <Fields.Text
                                    as="span"
                                    slug="language-popup-suggestion-text"
                                    scope="global"
                                >
                                    Suggested location and language
                                </Fields.Text>{' '}
                                <button
                                    className="u-txt-medium"
                                    onClick={() => handleSelect(preferred)}
                                >
                                    {preferred.name}
                                </button>
                            </div>
                        )}
                        <button
                            className="languages-close"
                            onClick={() => setIsOpen(false)}
                        >
                            <Icons.Close />
                        </button>
                    </div>
                    <div>
                        <div className="languages__body">
                            {all.map(({ name, countries }) => (
                                <React.Fragment key={name}>
                                    <p className="paragraph paragraph--big paragraph--medium">
                                        {name}
                                    </p>
                                    <ul>
                                        {countries?.map((item) => (
                                            <SingleLanguage
                                                current={current}
                                                item={item}
                                                key={item.code}
                                                onClick={handleSelect}
                                            />
                                        ))}
                                    </ul>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
