import { useEffect, useState } from 'react'
import { fetchOffers } from '@/modules/hatch/fetchOffers'

export const useHatchOffers = (affiliateId, productId, region) => {
    const [offers, setOffers] = useState([])
    const [, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const offers = await fetchOffers(affiliateId, productId, region)
                setOffers(offers)
            } catch (e) {
                console.error(e)
            }
            setIsLoading(false)
        }
        fetchData()
    }, [productId])

    return { offers }
}
