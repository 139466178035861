import React from 'react'
import { useHatchOffers } from '@/modules/hatch/hooks/useHatchOffers'

export const HatchContext = React.createContext({})

export const HatchProvider = ({
    affiliateId,
    productId,
    countryCode,
    children,
}) => {
    const { offers, isLoading } = useHatchOffers(
        affiliateId,
        productId,
        countryCode
    )
    const context = {
        offers,
        isLoading,
    }
    return (
        <HatchContext.Provider value={context}>
            {children}
        </HatchContext.Provider>
    )
}
