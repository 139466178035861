import React from 'react'
import { useLanguagesPopup } from '@/components/languages/hooks/useLanguages'
import { useLocales } from '@hako/core'

export const LanguageSwitcher = () => {
    const { isOpen, setIsOpen } = useLanguagesPopup()
    const { current } = useLocales()
    return (
        <button className="language-switcher" onClick={() => setIsOpen(true)}>
            {current?.name ?? '(inactive)'}
        </button>
    )
}
