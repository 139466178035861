import Arrow from '../icons/arrow.svg'
import ArrowBreadcrumbs from '../icons/arrow-breadcrumbs.svg'
import ArrowBreadcrumbsWhite from '../icons/arrow-breadcrumbs__white.svg'
import ArrowBack from '../icons/arrow-back.svg'
import Check from '../icons/check.svg'
import ChevronDown from '../icons/chevron-down.svg'
import Close from '../icons/close.svg'
import Evnia from '../icons/evnia.svg'
import Facebook from '../icons/facebook.svg'
import Globe from '../icons/globe.svg'
import Info from '../icons/info.svg'
import Instagram from '../icons/instagram.svg'
import Linkedin from '../icons/linkedin.svg'
import Philips from '../icons/Philips.svg'
import Play from '../icons/play.svg'
import Twitter from '../icons/twitter.svg'

export {
    Arrow,
    ArrowBreadcrumbs,
    ArrowBreadcrumbsWhite,
    ArrowBack,
    Check,
    ChevronDown,
    Close,
    Evnia,
    Facebook,
    Globe,
    Info,
    Instagram,
    Linkedin,
    Philips,
    Play,
    Twitter,
}
