import { useMenu } from '@hako/core'
import ActiveLink from '@/components/ActiveLink'

export const Menu = () => {
    const menu = useMenu('header')

    if (!menu?.items) {
        return null
    }

    return (
        <nav className="menu">
            {menu.items.map((item, index) => {
                return (
                    <ActiveLink
                        key={index}
                        href={item.value}
                        target={item.target}
                    >
                        {item.name}
                    </ActiveLink>
                )
            })}
        </nav>
    )
}
