import { useMobileMenu } from '@/components/mobile-menu/hooks/useMobileMenu'

export const Hamburger = () => {
    const { isOpen, setIsOpen } = useMobileMenu()
    return (
        <button
            onClick={() => setIsOpen((state) => !state)}
            className={`hamburger ${isOpen ? 'hamburger--active' : ''}`}
        >
            <span></span>
            <span></span>
            <span></span>
        </button>
    )
}
