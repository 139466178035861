import { Logo } from '@/components/Logo'
import { Menu } from '@/components/Menu'
import { SupportLink } from '@/components/SupportLink'
import { BuyButton } from '@/components/BuyButton'
import { LanguagePopupProvider } from '@/components/languages/LanguagePopupProvider'
import { LanguagePopup } from '@/components/languages/LanguagePopup'
import { MobileMenu } from '@/components/mobile-menu/MobileMenu'
import { MobileProductBar } from '@/components/mobile-menu/MobileProductBar'
import { Hamburger } from '@/components/mobile-menu/Hamburger'
import { MobileMenuProvider } from '@/components/mobile-menu/MobileMenuProvider'
import { Header } from '@/components/Header'
import { LanguageSwitcher } from '@/components/languages/LanguageSwitcher'

export const Navigation = () => {
    return (
        <>
            <LanguagePopupProvider>
                <MobileMenuProvider>
                    <Header>
                        <div className="grid-container grid u-justify-content-space-between">
                            <Logo />
                            <Menu />
                            <div className="header-right u-flex-row u-align-items-center u-justify-self-flex-end column-gap-xsmall">
                                <LanguageSwitcher />
                                <SupportLink />
                                <BuyButton />
                            </div>
                            <Hamburger />
                        </div>
                    </Header>

                    <MobileMenu />
                    <MobileProductBar />
                    <LanguagePopup />
                </MobileMenuProvider>
            </LanguagePopupProvider>
        </>
    )
}
