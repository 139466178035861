import React, { useState } from 'react'

export const MobileMenuContext = React.createContext()
export const MobileMenuProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <MobileMenuContext.Provider value={{ isOpen, setIsOpen }}>
            {children}
        </MobileMenuContext.Provider>
    )
}
