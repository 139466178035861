import Link from 'next/link'
import * as Icons from './Icons'

export const Logo = () => {
    return (
        <div className="logo">
            <Link href="/" aria-label="Link to homepage">
                <Icons.Evnia />
            </Link>
        </div>
    )
}
