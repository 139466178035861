import { useContext } from 'react'
import { ProductContext } from '@/pages/products/ProductProvider'

export const useProduct = () => {
    const context = useContext(ProductContext)
    const { id } = context

    if (!id) {
        return null
    }

    return context
}
