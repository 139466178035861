import { useMenu } from '@hako/core'
import ActiveLink from '@/components/ActiveLink'
import { useMobileMenu } from '@/components/mobile-menu/hooks/useMobileMenu'
import { LanguageSwitcher } from '@/components/languages/LanguageSwitcher'

export const MobileMenu = () => {
    const mobileMenu = useMobileMenu()
    const menu = useMenu('header')
    return (
        <nav
            role="navigation"
            className={`mobile-menu ${
                mobileMenu.isOpen ? 'mobile-menu--active' : ''
            }`}
        >
            <ul>
                {menu.items.map((item, index) => {
                    return (
                        <li key={index}>
                            <ActiveLink href={item.value} target={item.target}>
                                {item.name}
                            </ActiveLink>
                        </li>
                    )
                })}
            </ul>
            <LanguageSwitcher />
        </nav>
    )
}
